import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { MasterSupplierModel, MerchieStoreModel } from '@models';
import { ReportModel } from '../models/survey-report/report.model';
import { environment } from '@env/environment';
import { ProductModel } from '../models/product/product.model';

@Injectable({
    providedIn: 'root'
})
export class SalesService
{
    private url = '/sales';

    constructor(
        private http: HttpClient,
    )
    {
    }

    getMasterSuppliers(): Promise<MasterSupplierModel[]>
    {
        const url = `${this.url}/getMasterSuppliers`;

        return this.http
            .get<MasterSupplierModel[]>(url)
            .toPromise();
    }

    getProductImage(barcode: string, productName: string): Promise<any>
    {
        const url = `https://www.googleapis.com/customsearch/v1`;
        let params = new HttpParams()
            .append('key', environment.googleImageKey)
            .append('searchType', 'image')
            .append('num', '1')
            .append('cx', '2422c5bd22ff2409f');

        if (barcode !== null && barcode !== '')
        {
            params = params.append('q', barcode);
        }

        return this.http.get<any>(url, { params, })
            .toPromise();

        // try
        // {
        //     let response = await fetch(url);
        //     let data = await response.json();
        //
        //     if (data.items && data.items.length > 0)
        //     {
        //         return data.items[0].link;
        //     }
        //     else
        //     {
        //         // Fallback to product name if no image is found for the barcode
        //         query = encodeURIComponent(productName);
        //         url = `https://www.googleapis.com/customsearch/v1?key=AIzaSyAiaXJq3dE5BTHkpPZeapi2zS4-OM8HMew&cx=2422c5bd22ff2409f&q=${query}&searchType=image&num=1`;
        //         response = await fetch(url);
        //         data = await response.json();
        //
        //         if (data.items && data.items.length > 0)
        //         {
        //             return data.items[0].link;
        //         }
        //         else
        //         {
        //             return null;
        //         }
        //     }
        // }
        // catch (error)
        // {
        //     console.error('Error fetching product image:', error);
        //
        //     return null;
        // }
    }

    getStores(): Promise<MerchieStoreModel[]>
    {
        const url = `${this.url}/getStores`;

        return this.http
            .get<MerchieStoreModel[]>(url)
            .toPromise();
    }

    getReport(masterSupplierId: number, selectedStores: MerchieStoreModel[]): Promise<ReportModel>
    {
        const url = `${this.url}/getReport`;
        let params = new HttpParams()
            .set('master_supplier_id', masterSupplierId.toString());

        for (const selectedStore of selectedStores)
        {
            params = params.append('store_codes[]', selectedStore.store_code);
        }

        return this.http
            .get<ReportModel>(url, { params, })
            .toPromise();
    }
}
