import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';

import { AuthGuard } from '@core/services';

const routes: Routes = [
    { path: 'dashboard', canActivate: [AuthGuard], loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule), },
    { path: 'auth', loadChildren: () => import('./@auth/auth.module').then(m => m.AuthModule) },
    { path: 'order/:storeId', loadChildren: () => import('./@order/order/order.module').then((m) => m.OrderModule) },
    {
        path: 'order/:storeId/confirmation',
        loadChildren: () => import('./@order/order-confirmation/order-confirmation.module').then((m) => m.OrderConfirmationModule)
    },
    {
        path: 'public-photo-report', loadChildren: () =>
            import('./pages/photo-reports-share/photo-reports-share.module').then(m => m.PhotoReportsShareModule)
    },
    {
        path: 'public-survey-report', loadChildren: () =>
            import('./pages/public-survey-report/public-survey-report.module').then(m => m.PublicSurveyReportModule)
    },
    { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    { path: '**', redirectTo: 'dashboard' },
];

const config: ExtraOptions = {
    useHash: false,
    scrollPositionRestoration: 'enabled',
};

@NgModule({
    imports: [RouterModule.forRoot(routes, config)],
    exports: [RouterModule]
})
export class AppRoutingModule
{
}
