import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpRequest } from '@angular/common/http';

import {
    NB_AUTH_TOKEN_INTERCEPTOR_FILTER,
    NbAuthJWTInterceptor,
    NbAuthJWTToken,
    NbAuthModule,
    NbPasswordAuthStrategy
} from '@nebular/auth';

import { throwIfAlreadyLoaded } from './module-import-guard';
import { AuthGuard, EndpointInterceptor, LayoutService } from './services';

import { environment } from '@env/environment';

const formSetting: any = {
    redirectDelay: 1000, // delay before redirect after a successful login, while success message is shown to the user
    strategy: 'email',  // strategy id key.
    rememberMe: false,   // whether to show or not the `rememberMe` checkbox
    showMessages: {     // show/not show success/error messages
        success: true,
        error: true,
    },
    terms: false,
    socialLinks: null, // social links at the bottom of a page
};

export const NB_CORE_PROVIDERS = [
    ...NbAuthModule.forRoot({

        strategies: [
            NbPasswordAuthStrategy.setup({
                name: 'email',

                token: {
                    class: NbAuthJWTToken,
                    key: 'access_token',
                },

                baseEndpoint: environment.domain,
                login: {
                    endpoint: '/auth/login',
                    method: 'post'
                },
                register: {
                    endpoint: '/auth/register',
                    method: 'post'
                },
                logout: {
                    endpoint: '/auth/logout',
                    method: 'get',
                    redirect: {
                        success: '/auth'
                    }
                },
                refreshToken: {
                    endpoint: '/auth/refresh',
                    method: 'post', // laravel will throw error if not post
                }
            }),
        ],
        forms: {
            login: formSetting,
            register: formSetting,
            requestPassword: false,
            resetPassword: false,
            logout: formSetting,
            validation: {
                password: {
                    required: true,
                    minLength: 4,
                    maxLength: 50,
                },
                email: {
                    required: true,
                },
                fullName: {
                    required: false,
                    minLength: 4,
                    maxLength: 50,
                },
            },
        },
    }).providers,
    LayoutService,
    AuthGuard,
    {
        /**
         * Need to add here every route that should send without token
         */
        provide: NB_AUTH_TOKEN_INTERCEPTOR_FILTER, useValue: (req: HttpRequest<any>) =>
        {
            const endpoints = [
                '/auth/refresh',
                '/auth/login',
                '/photos/getReportShare',
                'www.googleapis.com/customsearch',
            ];

            for (const endpoint of endpoints)
            {
                if (req.url.indexOf(endpoint) !== -1)
                {
                    return true;
                }
            }

            return false;
        },
    },
    { provide: HTTP_INTERCEPTORS, useClass: NbAuthJWTInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: EndpointInterceptor, multi: true }
];

@NgModule({
    imports: [
        CommonModule,
    ],
    exports: [
        NbAuthModule,
    ],
    declarations: [],
})
export class CoreModule
{
    constructor(@Optional() @SkipSelf() parentModule: CoreModule)
    {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }

    static forRoot(): ModuleWithProviders<CoreModule>
    {
        return {
            ngModule: CoreModule,
            providers: [
                ...NB_CORE_PROVIDERS,
            ],
        };
    }
}
